<template>
  <div class="application">
    <el-dialog
      title="申请贷款"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      :close-on-click-modal="false"
      width="70%"
      height="100%"
    >
     <el-form :model="form"  :rules="rules" label-position="left" ref="ruleForm">
      <div class="LoanApplication">
        <!-- 服务网点 -->
        <div class="serviceOutlets">
            <el-form-item label="服务网点">
              <el-select
                @change="LookChange"
                 value-key="departId"
                v-model="form.OrganizationItem"
                style="width: 355px"
              >
                <el-option
                  v-for="item in institutions"
                  :key="item.departId"
                  :label="item.departName"
                  :value="item"
                ></el-option
              ></el-select>
            </el-form-item>
        </div>
        <!-- 基础信息 -->
        <div class="BasicInformation">
          <div class="title">
            <p>基础信息</p>
          </div>
          <div class="textContent">
            <div class="Left">
                <el-form-item label="申请人">
                  <el-input
                    v-model="form.applicantName"
                    :disabled=true
                    placeholder=""
                  ></el-input>
                </el-form-item>
                <el-form-item label="手机号">
                  <el-input v-model="form.phone"   :disabled=true placeholder=""></el-input>
                </el-form-item>

            </div>
            <div class="Right">

                <el-form-item label="身份证号">
                  <el-input v-model="form.IdCode"   :disabled=true placeholder=""></el-input>
                </el-form-item>
                <el-form-item label="所在地址">
                  <el-cascader
                    style="width: 190px"
                    ref="cascader"
                    v-model="form.areaCode"
                    :props="props"
                    :options="options"
                    :show-all-levels="false"
                    @change="regionChange"
                  ></el-cascader>
                </el-form-item>


            </div>
          </div>
        </div>

        <!-- 经营信息 -->
        <div class="ManagementInformation">
          <div class="title">
            <p>经营信息</p>
          </div>
          <div class="textContent">
              <div class="textContent_top">
                <div class="Left">
                  <el-form-item label="年经营收入" prop="YearIncome" >
                    <el-input
                      v-model="form.YearIncome"
                      type="number"
                      placeholder=""
                    ></el-input>
                  </el-form-item>
                  <p>元</p>
                  <p>{{ BigYearIncome }}</p>
                </div>
                <div class="Right" >
                  <el-form-item label="实际经营年限" prop="YearManagement" >
                    <el-input
                      v-model="form.YearManagement"
                      placeholder=""
                      type="number"
                    ></el-input>

                  </el-form-item>
                  <p>年</p>
                </div>
              </div>
              <div class="textContent_bottom">
                <div class="title">
                  <span>种养品种</span>

                </div>
                <div class="addBox">
                  <div class="addList">

                      <div
                    class="Types"
                    v-for="(item, index) in addList"
                    :key="index"
                  >
                    <el-select    filterable v-model="item.operatCrop" style="width: 200px"  placeholder="可通过关键字筛选"
                    clearable>
                      <el-option
                        v-for="(item, index) in types"
                        :key="index"
                        :value="item.categoryName"
                        :label="item.categoryName"

                      ></el-option
                    ></el-select>
                    <el-input
                      v-model="item.operatCount"
                      placeholder="请输入数量"
                      type="number"
                    ></el-input>
                    <el-select v-model="item.operatUnit" style="width: 200px">
                      <el-option
                        v-for="(item, index) in areaType"
                        :key="index"
                        :value="item.value"
                        :label="item.label"
                      ></el-option
                    ></el-select>
                    <el-button
                      type="primary"
                      @click="delItem(index)"
                      style="margin-left: 20px"
                      >删除</el-button
                    >
                  </div>
                  </div>
                  <div class="addBtn">
                     <el-button type="primary" style="float: right" @click="add()"
                    >添加</el-button
                  >
                  </div>

                </div>
              </div>
          </div>
        </div>

        <!-- 贷款信息 -->
        <div class="LoanInformation">
          <div class="title">
            <p>贷款信息</p>
          </div>
          <div class="textContent">
            <div class="LoanInformation_top">
              <div class="Left">
                <!-- :style="{
                  border:(showMessage? '-color:#f56c6c' : '-color:black')
                }" -->
 <!-- style="border-color:#f56c6c" -->
                  <el-form-item label="贷款金额" prop="LoansAmount">
                    <el-input
                    :class="showMessage ? 'active' :''"
                    @blur="clearMessage"
                    @focus="clearMessage"
                      v-model="form.LoansAmount"
                      placeholder=""
                      type="number"
                    ></el-input>

                    <div v-show="showMessage"
                     style="color: #f56c6c;  font-size: 12px;
                    line-height: 1;
                    padding-top: 4px;
                    position: absolute;
                    top: 100%;
                    left: 0;" class="item__error">{{promptMessage}}</div>
                  </el-form-item>
                <p>{{ bigNumber }}</p>


              </div>
            </div>
            <div class="LoanInformation_bottom">
              <span>资金用途</span>
              <el-input
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 4 }"
                placeholder="请输入内容"
                v-model="form.FundsUse"
              >
              </el-input>
            </div>
          </div>
        </div>

        <!-- 身份证 -->
        <div class="IDCard">
          <div class="Left">
            <p class="title"><span>身份证人面像</span></p>

            <el-image :src="baseUrl + 'admin/file/get?ossFilePath=' + logoUrl2">
              <div slot="error" class="image-slot">
                <img src="../../assets/icon/imgError.png" />
              </div>
            </el-image>
            <el-upload
              class="upload-demo"
              accept="|.png,.jpg,"
              :action="uploadUrl"
              name="files"
              :auto-upload="false"
              :on-change="handleChange2"
              :file-list="fileList"
              :show-file-list="false"
            >
              <p><el-button type="primary">更换</el-button></p>
            </el-upload>
          </div>
          <div class="Right">
            <p><span>身份证国徽像</span></p>
            <el-image :src="baseUrl + 'admin/file/get?ossFilePath=' + logoUrl3">
              <div slot="error" class="image-slot">
                <img src="../../assets/icon/imgError.png" />
              </div>
            </el-image>
            <el-upload
              class="upload-demo"
              accept="|.png,.jpg,"
              :action="uploadUrl"
              name="files"
              :auto-upload="false"
              :on-change="handleChange3"
              :file-list="fileList"
              :show-file-list="false"
            >
              <p><el-button type="primary">更换</el-button></p>
            </el-upload>
          </div>
        </div>

        <!-- 其他附件 -->
        <div class="OtherAccessories">
          <div class="title">
            <p>附件</p>
          </div>
          <div class="textContent">
            <!-- accept="|.doc,.docx,.xls,.xlsx,.pdf,.zip,.7z"
              handleRemove
            -->
            <el-upload
              ref="pic1"
              multiple
              class="upload-demo"
              :limit="6"
              :action="uploadUrl"
              name="files"
              :auto-upload="false"
              :on-change="handleChangeUp"
              :file-list="fileList"
              :show-file-list="false"
            >
              <i class="el-icon-download"></i>
            </el-upload>

            <div class="Item" v-for="(item, index) in fileList" :key="index">
              <div class="del" @click="del(index)">X</div>
              <el-image
                :src="'/api/admin/file/get?ossFilePath=' + item.imgUrl"
                @click="AddFiles(item)"
              >
                <div slot="error" class="image-slot">
                  <!-- <svg t="1702953208684" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3250" width="32" height="32"><path d="M293.7 635.7H643V592H293.7v43.7z m291.1-247.4H293.7V432h291.1v-43.7z m320.1-203.7v43.7l-43.6-43.6v1.2L816.4 141h1.1l-43.4-43.4 116.3-0.3h-684v87.3h-87.3v742.2h698.5v-87.3h87.3V184.6z m-654.8-43.7h508.3l-6.1 6.1 37.5 37.5H250.1v-43.6z m523.8 742.2H162.7V228.2h495l-4.6 4.6 120.8 120.8v529.5z m43.7-567.6L686.7 184.9l130.8-0.3v130.9z m43.7 480.3h-43.7V212.4l37.5 37.5 6.1-6.1v552z m-567.6-262H643v-43.7H293.7v43.7z m0 203.8H643v-43.7H293.7v43.7z" p-id="3251"></path></svg> -->
                  <i class="el-icon-document-copy" @click="AddFiles(item)"></i>
                </div>
              </el-image>
              <span>
                {{ item.FilesName }}
              </span>
            </div>
          </div>
        </div>
      </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button style="margin-top: 20px" @click="back">取消</el-button>
        <el-button style="margin-top: 20px" @click="SaveBtn"
          >保存草稿</el-button
        >
        <el-button type="primary" @click="submitBtn"> 提交 </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// 图片上传
import { uploadFile } from "@/api/fileApi";
//用户申请贷款
import {
  ApplicationLoan,
  AreaTree,
  getBreedInTrust,
  selectDeptName,
} from "@/api/RuralFinance";

import { digitUppercase } from "@/util/numConvert.js";
//查询所在地区

export default {
  props: {
    areaCode: [{ required: true, trigger: "change" }],
  },
  data() {
    setTimeout(() => {
      this.areaHasChild = this.identityObj.areaCode ? false : true;
      this.form.areaCode = this.identityObj.areaCode;
      if (this.identityObj.areaCode.length >= 6) {
        // this.getlandScope(this.identityObj.areaCode.substring(0, 6));
      }
      this.form.areaName = this.identityObj.areaFullName
        ? this.identityObj.areaFullName.split("/").join("")
        : "";
    }, 100);
    // //判断贷款的额度是否到达标准
    // const checkLoansAmount = (rule, value, callback) => {
    //     if (!value) {
    //       return callback(new Error('请输入贷款金额'));
    //     }
    //     setTimeout(() => {
    //         if (value > this.maxMoney) {
    //           callback(new Error('超出贷款额度'));
    //         } else if(value < this.minMoney) {
    //           callback('低于贷款额度');
    //       }
    //       else {
    //       callback();
    //     }
    //     }, 1000);
    //   };
    return {
      showApplyFor: false, //判断当前页面是农户还是公司
      fileList: [], //上传图片的列表,
      logoUrl: "", // 存储营业执照
      logoUrl2: "", // 存储人面像图片
      logoUrl3: "", // 存储国徽像图片
      dialogVisible: true,
      files: [], //上传的文件
      uploadUrl: `${window.globalUrl.HOME_API_WEI}/admin/file/upload`, //上传图片的地址
      form: {
        areaCode: "",
        area: "",
        applicantName: "", //申请人
        phone: "", //手机号
        IdCode: "", //身份证号
        // AllAddress: "", //详细地址
        YearIncome: "", //年经营收入
        YearManagement: "", //实际经营收入
        LoansAmount: "", //贷款金额
        FundsUse: "", //资金用途
        OrganizationItem: "",
      }, //这是输入基础信息
      options: [], // 所在地区
      financeProductId: "", //产品单号
      //主体类型
      types: [],
      areaType: [
        {
          label: "亩",
          value: "0",
        },
        {
          label: "尾",
          value: "1",
        },
        {
          label: "羽",
          value: "2",
        },
        {
          label: "头",
          value: "3",
        },
        {
          label: "窝",
          value: "4",
        },
        {
          label: "匹",
          value: "5",
        },
        // {
        //   label: "平方米",
        //   value: "2",
        // },
      ],
      textarea2: "",
      regionOptions: [],
      baseUrl: window.globalUrl.BASE_API,
      props: {
        label: "areaName",
        children: "children",
        value: "id",
        fullPathName: "fullPathName",
        checkStrictly: true,
        emitPath: false,
      },
      maxMoney: "",
      minMoney: "",
      addList: [
        {
          operatCrop: "", //种养品种
          operatCount: "", //输入数量
          operatUnit: "", // 地区的类型
        },
      ],
      FinancialDetails: [],
      institutions: [],

      rules:{
        // ,validator:checkLoansAmount,
        //    LoansAmount:[
        //     {required:true, message: '请输入贷款金额',   trigger: 'change'}
        // ],
        YearIncome:[{
         message: '请输入年经营收入', trigger: 'blur'
        }],
        YearManagement:[{
         message: '实际经营年限', trigger: 'blur'
        }],
      },
      isTrueMoney:'',
      showMessage:false,//是否显示提示
      promptMessage:'',//提示语
    };
  },

  computed: {
    ...mapGetters(["identityObj", "topSelectAreacode"]),
    bigNumber() {
      if (this.form && this.form.LoansAmount) {
        return digitUppercase(this.form.LoansAmount);
      } else {
        return "";
      }
    },

    BigYearIncome() {
      if (this.form.YearIncome == 0) {
        return "";
      } else {
        return digitUppercase(this.form.YearIncome);
      }
    },
  },
  mounted() {
    // console.log(this.form, "这里有值嘛");
    //回显
    this.FirstShow();
    //种养品种
    this.getBreedInTrust();

  },

  methods: {
    //新增
    add() {
      this.addList.push({
        operatCrop: "", //种养品种
        operatCount: "", //输入数量
        operatUnit: "", // 地区的类型
      });
    },
    //清除消息提示语
    clearMessage(){
      this.promptMessage="";
      this.showMessage=false;
    },

    // 删除
    delItem(index) {
      this.addList.splice(index, 1);
    },
    LookChange(val){
      console.log(val,'查看val')
      this.institutions.filter(item=>{
        if(item.departId==val.departId){
           return  this.financeProductId=item.financeProductId
        }
      })
      // console.log('this.financeProductId',this.financeProductId)
    },
    //行政区做切换
    regionChange() {
      let node = this.$refs.cascader.getCheckedNodes()[0];
      this.areaHasChild = node.hasChildren;
      this.form.areaCode = node.value;
      this.form.areaName = node.pathLabels.join("");
      this.form.landType = "";
      setTimeout(() => {
        this.$refs.form.validateField("cascader");
      }, 100);
    },
    //回显
    FirstShow() {
      // console.log(this.identityObj, "回显");
      //手机号
      this.form.phone =
        this.identityObj.contactPhone || this.identityObj.userName;
      //申请主体名称
      this.form.applicantName =
        this.identityObj.bossName || this.identityObj.contactName;
      //身份证回显
      this.form.IdCode = this.identityObj.identityCode;
      this.logoUrl2 = this.identityObj.userCardFrontImg;
      this.logoUrl3 = this.identityObj.userCardBackImg;


      // console.log(this.logoUrl2);
    },
    //附件上传图片的删除
    del(index) {
      let newFileList = this.fileList.filter((item, ind) => {
        if (index == ind) {
          let newFileList = this.fileList.splice(ind, 1);
          return newFileList;
        }
      });
      console.log(newFileList);
    },
    //附件下载功能
    async AddFiles(item) {
      let dom = document.createElement("a");
      dom.style.display = "none";
      dom.href = "/api/admin/file/get?ossFilePath=" + item.imgUrl;
      dom.setAttribute("download", item.FilesName);
      document.body.appendChild(dom);
      dom.click();
    },
    //申请详情的弹窗
    handleClose() {
      this.$emit("closeDialog2", false);
    },
    back() {
      this.$emit("closeDialog2", false);
    },
    //上传
    async allUpLoad(file, type, num) {
      let param = new FormData();
      param.append("catalog", type);
      param.append("file", file.raw);
      let res = await uploadFile(param);
      let imgUrl = "/api/admin/file/get?ossFilePath=" + res.data.url;

      if (num == 1) {
        this.logoUrl = imgUrl;
      } else if (num == 2) {
        this.logoUrl2 = imgUrl;
      } else {
        this.logoUrl3 = imgUrl;
      }
    },
    //上传法人身份证人面像
    handleChange2(file) {
      this.allUpLoad(file, "LegalPersonIDCardL", 2);
    },
    //上传法人身份证国徽像
    handleChange3(file) {
      this.allUpLoad(file, "LegalPersonIDCardR", 3);
    },
    async editObj(FinancialDetails) {
      // console.log(FinancialDetails, "查看");
      this.maxMoney = FinancialDetails.maxMoney;
      this.minMoney = FinancialDetails.minMoney;
      // this.financeProductId = FinancialDetails.financeProductId;
      let params = {
        // parentCode: FinancialDetails.areaCode,
        parentCode: "",
      };
      let res = await AreaTree(params);
      this.options = res.data;
      this.FinancialDetails = FinancialDetails;
      this.selectDeptName();

    },
    //本产品所在区县的种养品种
    async getBreedInTrust() {
      let res = await getBreedInTrust();
      this.types = res.data;
    },
    //文件上传
    async handleChangeUp(file, fileList) {
      // console.log(fileList, "这是fileList");
      let param = new FormData();
      param.append("catalog", "financeAdd");
      param.append("file", file.raw);
      let res = await uploadFile(param);
      // console.log(res, "这是res");
      // let imgUrl = "/api/admin/file/get?ossFilePath=" + res.data.url;
      let FilesName = res.data.sourceFileName;
      let imgUrl = res.data.url;

      let item = {
        FilesName: FilesName,
        imgUrl: imgUrl,
      };
      this.files.push(item);
      this.fileList.push(item);
      // console.log(this.files, "查看");

      // this.files.push(fileList.name);
    },
  // 查看金额
    maxMoneyTip(){
           // 检查输入框的值是否为空
     if (!this.form.LoansAmount.trim()||this.form.LoansAmount=='') {
        this.promptMessage = '请输入贷款金额';
        this.showMessage = true;
        return;
      }

      // 检查输入框的值是否大于最大额度
      if (parseInt(this.form.LoansAmount) > this.maxMoney) {
        this.promptMessage = '超出贷款额度';
        this.showMessage = true;
        return;
      }

      // 检查输入框的值是否小于最小额度
      if (parseInt(this.form.LoansAmount) < this.minMoney) {
        this.promptMessage = '低于贷款额度';
        this.showMessage = true;
        return;
      }
  },
      //提交
      async submitBtn() {
      this.rules.YearIncome[0].required=true
      this.rules.YearManagement[0].required=true
      this.maxMoneyTip()
  //  this.$refs.ruleForm.validateField(LoansAmount);


    // this.rules.LoansAmount[0].required=true
      this.$refs.ruleForm.validate(async (valid, obj) => {
      if(valid||!this.form.LoansAmount.trim()||!this.form.LoansAmount==''){
        this.maxMoneyTip()
        if (this.showMessage) return;
          // if (this.form.YearIncome && this.form.YearManagement) {
        let area = this.$refs["cascader"].getCheckedNodes();
        let files = JSON.stringify(this.fileList);
        this.form.area = area[0].data.fullPathName;
        let params = {
          financeProductId: this.financeProductId,
          areaCode: this.form.areaCode,
          area: this.form.area,
          userType: 2,
          name: this.form.applicantName,
          idCardNo: this.form.IdCode,
          phone: this.form.phone,
          income: this.form.YearIncome,
          operatYear: this.form.YearManagement,
          operatList: this.addList,
          money: this.form.LoansAmount,
          use: this.form.FundsUse,
          userCardFrontImg: this.logoUrl2,
          userCardBackImg: this.logoUrl3,
          files: files,
          status: 1,
          departId: this.form.OrganizationItem.departId,
        };
        let res = await ApplicationLoan(params);
        if (res.code == 0) {
          // this.$emit("closeDialog2", false);
          this.$message.success(res.msg);
          this.$router.push("/memberCenter/financalService/applicationRecord");
        } else {
          this.$message.warning(res.msg);
        }
      // }
      //  else {
        // this.$message.info("请输入年经营收入和经营年限");
      // }
      }else{
        this.$message.error('请完善提交信息')
      }
      })
    },
    //用户保存草稿
    async SaveBtn() {
      // this.$refs.ruleForm.validate(async (valid, obj) => {
      // if(valid){
      // this.dialogVisible = false;
    this.rules.YearIncome[0].required=false
    this.rules.YearManagement[0].required=false
      // this.$refs.ruleForm.resetFields() //此时不会触发表单校验
      let area = this.$refs["cascader"].getCheckedNodes();
      this.form.area = area[0].data.fullPathName;
      let files = JSON.stringify(this.fileList);
      let params = {
        financeProductId: this.financeProductId,
        areaCode: this.form.areaCode,
        area: this.form.area,
        userType: 2,
        name: this.form.applicantName,
        // address: this.form.AllAddress,
        idCardNo: this.form.IdCode,
        phone: this.form.phone,
        income: this.form.YearIncome,
        operatYear: this.form.YearManagement,
        operatList: this.addList,
        money: this.form.LoansAmount,
        use: this.form.FundsUse,
        userCardFrontImg: this.logoUrl2,
        userCardBackImg: this.logoUrl3,
        files: files,
        status: 0,
        departId: this.form.OrganizationItem.departId,
      };
      let res = await ApplicationLoan(params);
      if (res.code == 0) {
        this.$message.success("保存成功");
        this.$router.push("/memberCenter/financalService/applicationRecord");
        // this.$emit("closeDialog2", false);
      } else {
        this.$message.info(res.msg);
      }
    // }
    // else{
    //     this.$message.error('请完善提交信息')
    //   }
    // })

    },
    //查询所属机构
    async selectDeptName() {
      let areaCode =
        this.identityObj.areaCode.length > 3
          ? this.identityObj.areaCode.substring(0, 6)
          : this.identityObj.areaCode;
      let params = {
        areaCode: areaCode,
        financeProductId:this.FinancialDetails.financeProductId,
      };

      let res = await selectDeptName(params);

      this.form.OrganizationItem=res.data[0]
      this.financeProductId=res.data[0].financeProductId

      // let dataList=[]
      // res.data.forEach((item) => {
      //   dataList.push({
      //     departId:item.departId,
      //     departName:item.departName
      //   })
      // });
      this.institutions =res.data;

    },
  },
};
</script>

<style lang="less" scoped>
.application {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .LoanApplication {
    width: 100%;
    height: 100%;
    /*服务网点*/
    .serviceOutlets {
      /deep/.el-form-item {
        display: flex;
        margin-top: 20px;
        margin-left: 33px;
        .el-form-item__label {
          width: 96px;
          height: 40px;
          font-size: 15px;
          font-weight: bold;
          text-align: center;
        }
        .el-form-item__content {
          width: 352px;
        }
      }
      /*是否平移*/
      /* /deep/.el-form-item__content{
            margin-left: 119px;
          }*/
    }
    /*基础信息*/
    .BasicInformation {
      display: flex;
      border: solid 1px #cccc;
      .title {
        width: 10%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        p {
          font-size: 15px;
          font-weight: bold;
          text-align: center;
        }
      }
      .textContent {
        width: 80%;
        display: flex;
        padding: 10px;
        .Left {
          width: 50%;
          height: 100%;
          /deep/.el-form-item {
            width: 100%;
            .el-input {
              width: 200px;
            }
            .el-form-item__label {
              width: 124px;
              height: 40px;
            }
          }
        }
        .Right {
          width: 50%;
          height: 100%;
          /deep/.el-form-item {

            width: 100%;
            .el-input {
              width: 200px;
            }
            .el-form-item__label {
              width: 124px;
              height: 40px;
            }
          }
        }
      }
    }
    /*经营信息*/
    .ManagementInformation {
      display: flex;
      border: solid 1px #cccc;
      .title {
        width: 10%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        p {
          font-size: 15px;
          font-weight: bold;
          text-align: center;
        }
      }
      .textContent {
        width: 80%;
        display: flex;
        flex-direction: column;
        .textContent_top {
          width: 100%;
          display: flex;
          padding: 10px;
          .Left {
            width: 50%;
            display: flex;
            /deep/.el-form-item {
              display: flex;
              .el-input {
                width: 150px;
              }
              .el-form-item__label {
                width: 124px;
                height: 40px;
              }
            }
            p {
              padding: 0px 10px;
            }
          }
          .Right {
            width: 50%;
            display: flex;
            /deep/.el-form-item {
              display: flex;
              .el-input {
                width: 150px;
              }
              .el-form-item__label {
                width: 124px;
                height: 40px;
              }
            }
            p {
              padding-left: 10px;
            }
          }
        }
        .textContent_bottom {
          width: 100%;
          height: 100%;
          display: flex;
          span {
            width: 100px;
            height: 100%;
            text-align: center;
          }
          .addBox{
            width: 80%;
            display: flex;
            justify-content: space-between;
            .Types {
                        display: flex;
                        padding-bottom: 5px;
                        padding-left: 30px;
                        .el-input {
                          margin: 0px 5px;
                          width: 200px;
                        }
                      }
            }
            .addBtn{
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              button{
                margin-bottom:5px ;
                margin-left:5px;
              }
            }

        }
      }
    }
    /*贷款信息*/
    .LoanInformation {
      display: flex;
      border: solid 1px #cccc;
      .title {
        width: 10%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        p {
          font-size: 15px;
          font-weight: bold;
          text-align: center;
        }
      }
      .textContent {
        width: 80%;
        display: flex;
        flex-direction: column;
        padding: 10px;
        .LoanInformation_top {

          .Left {
            display: flex;
            /deep/.el-form-item {
              display: flex;
              .el-form-item__error {
                color: #f56c6c;
                font-size: 12px;
                line-height: 1;
                padding-top: 4px;
                position: absolute;
                top: 100%;
                left: 0;
            }
              .el-form-item__label {
                width: 96px;
                height: 40px;
              }
              .el-form-item__content{
                .active{

                  input {
                    border:solid 1px #f56c6c;
                  }
                }
              }

            }
            p {
              margin-left: 10px;
            }
            .Exceed {
              color: red;
            }
          }
        }
        .LoanInformation_bottom {
          width: 100%;
          display: flex;
          span {
            width: 100px;
            line-height: 50px;
          }
        }
      }
    }
    /*法人身份证*/
    .IDCard {
      width: 100%;
      display: flex;
      border: solid 1px #ccc;
      .Left {
        width: 50%;
        display: flex;
        justify-content: space-around;

        /deep/.el-image {
          img {
            width: 150px;
            height: 80px;
            padding: 5px 0px;
          }
          .image-slot {
            img {
              width: 100px;
              height: 80px;
            }
          }
        }
        p {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
        }
        .title {
          font-size: 15px;
          font-weight: bold;
        }
      }
      .Right {
        width: 50%;
        display: flex;
        justify-content: space-around;
        /deep/.el-image {
          img {
            width: 150px;
            height: 80px;
            padding: 5px 0px;
          }
          .image-slot {
            img {
              width: 100px;
              height: 80px;
            }
          }
        }

        p {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
        }
        .title {
          font-size: 15px;
          font-weight: bold;
        }
      }
    }
    /*其他附件*/
    .OtherAccessories {
      border: solid 1px #ccc;
      display: flex;
      .title {
        width: 10%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        p {
          font-size: 15px;
          font-weight: bold;
          text-align: center;
          i {
            color: red;
          }
        }
      }
      .textContent {
        width: 100%;
        height: 120px;
        display: flex;
        border: solid 1px #ccc;
        .Item {
          width: 150px;
          height: 100%;
          display: flex;
          flex-direction: column;
          text-align: center;
          position: relative;
          padding: 10px;
          .el-image {
            width: 150px;
            height: 85px;
            background: #e6e3e3;
            img {
              width: 200px;
              height: 50px;
            }
            i {
              font-size: 50px;
              font-weight: bold;
              margin-top: 20px;
            }
          }
          span {
            margin-top: 5px;
          }
          .del {
            width: 20px;
            height: 20px;
            border: solid 1px black;
            border-radius: 50px;
            display: none;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 5000;
            text-align: center;
            line-height: 20px;
          }
        }
        .Item:hover {
          .del {
            display: block;
          }
        }
        .upload-demo {
          i {
            font-size: 50px;
            font-weight: bold;
            margin-top: 30px;
          }
        }
      }
    }
  }
}
</style>
