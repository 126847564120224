<template>
  <div class="LoanDetails">
    <!-- 贷款申请 -->
    <Application
      v-if="showApplyFor"
      @closeDialog="closeDialog"
      ref="Application"
    />
    <FarmerApplication
      v-if="farmerShow"
      @closeDialog2="closeDialog2"
      ref="FarmerApplication"
    />

    <!-- 头部指引  -->
    <div class="headerLogo">
      <h5>金融超市 > 产品详情 > {{ FinancialDetails.title }}</h5>
    </div>

    <!-- 贷款详情 -->
    <div class="LoanInformation">
      <div class="LoanInformation_top">
        <img
          :src="'/api/admin/file/get?ossFilePath=' + FinancialDetails.logoUrl"
          alt=""
        />

        <h4>{{ FinancialDetails.title }}</h4>
      </div>
      <div class="LoanInformation_center">
        <div
          v-show="FinancialDetails.minMoney || FinancialDetails.maxMoney"
          class="LoanInformation_bottom_item"
        >
          <span class="ColItem">
            {{
              FinancialDetails.minMoney == FinancialDetails.maxMoney ||
              FinancialDetails.minMoney == 0
                ? FinancialDetails.ToMaxMoney
                : FinancialDetails.ToMinMoney +
                  "~" +
                  FinancialDetails.ToMaxMoney
            }}
          </span>
          <span class="ColItem2">贷款额度</span>
        </div>
        <div
          v-show="FinancialDetails.maxTime"
          class="LoanInformation_bottom_item"
        >
          <span class="ColItem">
            {{
              FinancialDetails.minTime == FinancialDetails.maxTime ||
              FinancialDetails.minTime == 0
                ? FinancialDetails.maxTime + "个月"
                : FinancialDetails.minTime +
                  "个月~" +
                  FinancialDetails.maxTime +
                  "个月"
            }}
          </span>
          <span class="ColItem2">贷款期限</span>
        </div>
        <div
          v-show="FinancialDetails.creditType"
          class="LoanInformation_bottom_item"
        >
          <span class="ColItem">
            {{ FinancialDetails.creditType }}
          </span>
          <span class="ColItem2">担保方式</span>
        </div>
        <div
          class="LoanInformation_bottom_item"
          v-show="FinancialDetails.minRate || FinancialDetails.maxRate"
        >
          <span class="ColItem">
            {{
              FinancialDetails.minRate == FinancialDetails.maxRate ||
              FinancialDetails.minRate == 0
                ? FinancialDetails.maxRate + "%"
                : FinancialDetails.minRate +
                  "% ~" +
                  FinancialDetails.maxRate +
                  "% "
            }}
          </span>
          <span class="ColItem2">贷款利率</span>
        </div>
        <el-button type="primary" @click="application(FinancialDetails)"
          >申请</el-button
        >
      </div>
      <div class="ModeOfRepayment_bottom">
        <div class="repayment">
          <div class="Bold">还款方式:</div>
        </div>
        <div class="BoldText">
          <span>
            {{ FinancialDetails.repayType }}
          </span>
        </div>
      </div>
    </div>

    <!-- 产品介绍 -->
    <div class="ProductIntroduction">
      <div class="ProductIntroductionItem">
        <div class="repayment">
          <div class="Bold">产品介绍:</div>
        </div>
        <div class="BoldText">
          <span>
            {{ FinancialDetails.introduction }}
          </span>
        </div>
      </div>
      <div class="ProductIntroductionItem">
        <!-- <p>
          <span class="Bold">服务对象:</span>
          <span class="BoldText">{{ FinancialDetails.serviceUser }}</span>
        </p> -->
        <div class="repayment">
          <div class="Bold">服务对象:</div>
        </div>
        <div class="BoldText">
          <span>
            {{ FinancialDetails.serviceUser }}
          </span>
        </div>
      </div>
    </div>

    <!-- 申请条件 -->
    <div class="ApplicationConditions">
      <div class="ProductIntroductionItem">
        <div class="repayment">
          <div class="Bold">申请条件:</div>
        </div>
        <div class="BoldText">
          <!-- 使用v-html -->
          <span v-html="FinancialDetails.applyCondition.replace(/\n/g, '<br>')">
          </span>
        </div>
      </div>
    </div>

    <!-- 相关材料 -->
    <div class="RelatedMaterials">
      <div class="ProductIntroductionItem">
        <div class="repayment">
          <div class="Bold">
            相关材料:
            <el-button
              type="primary"
              v-if="FinancialDetails.files !== '[]'"
              @click="Package(FinancialDetails.financeProductId)"
              >打包下载</el-button
            >
          </div>
        </div>
        <div class="BoldText" v-if="FinancialDetails.files !== '[]'">
          <ul>
            <li
              v-for="(item, index) in JSON.parse(FinancialDetails.files)"
              :key="index"
              @click="Download(item)"
            >
              <i class="el-icon-document-checked"></i>
              <span>{{ item.FilesName }}</span>
            </li>
          </ul>
        </div>
        <div class="BoldText" v-else>
          暂无相关附件
          <!-- <button @click="anniu">按钮</button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//申请弹窗
import Application from "./application";
import FarmerApplication from "./FarmerApplication";
import {
  DetailsFinancialProducts,
  ScreeningCondition,
  packageDownload,
} from "@/api/RuralFinance";
import { download } from "../../api/add";
import { mapGetters } from "vuex";
export default {
  name: "AgriculturalAssistanceProject9LoanDetails",
  components: {
    Application, //公司
    FarmerApplication, //个人经济组织
  },
  data() {
    return {
      showApplyFor: false, //是否展示贷款申请
      farmerShow: false, //展示
      FinancialDetails: [], //金融详情数据
      baseUrl: window.globalUrl.BASE_API,
      guarantee: "", //担保方式
      repayType: "", //还款方式
    };
  },
  computed: {
    ...mapGetters(["identityObj"]),
  },
  mounted() {
    //查询详情页数据
    this.DetailsFinancialProducts();
    //担保方式
    this.ScreeningCondition();
    // 还款方式
    this.Repayment();
  },

  methods: {
    anniu() {
      console.log("按钮", this.FinancialDetails.files);
    },
    // 关闭弹框
    closeDialog(showApplyFor) {
      this.showApplyFor = showApplyFor;
    },
    closeDialog2(farmerShow) {
      this.farmerShow = farmerShow;
    },
    //贷款方式
    async ScreeningCondition() {
      let params = "credit_type";
      let res = await ScreeningCondition(params);
      this.guarantee = res.data;
      // console.log(this.guarantee);
    },
    //还款方式
    async Repayment() {
      let params = "repay_type";
      let res = await ScreeningCondition(params);
      this.repayType = res.data;
    },
    application(FinancialDetails) {
      console.log(FinancialDetails, "查看这是什么");
      //开启申请弹窗
      if (!this.identityObj.identityTypeCode) {
        this.$message.info("请登录后进行操作");
        return;
      }
      if (
        this.identityObj.identityTypeCode != 2 &&
        this.identityObj.identityTypeCode != 3 &&
        this.identityObj.identityTypeCode != 4
      ) {
        this.$message.info("当前账户无权限，请切换角色后操作");
        return;
      }
      if (this.identityObj.identityTypeCode == 2) {
        this.farmerShow = true;
        this.$nextTick(() => {
          console.log(FinancialDetails, "查看传的FinancialDetails");
          // areaCode, financeProductId
          this.$refs.FarmerApplication.editObj(FinancialDetails);
        });
      } else {
        this.showApplyFor = true;
        this.$nextTick(() => {
          console.log(FinancialDetails, "查看传的FinancialDetails");
          this.$refs.Application.editObj(FinancialDetails);
        });
      }
    },
    //详情页数据
    async DetailsFinancialProducts() {
      let params = {
        financeProductId: this.$route.query.id,
      };
      let res = await DetailsFinancialProducts(params);

      this.FinancialDetails = res.data[0];
      console.log("this.FinancialDetails", this.FinancialDetails);
      this.ToWay();
    },
    ToWay() {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          //担保方式
          let ToGuaranteeMethods = this.guarantee;
          let arr = Array.from(this.FinancialDetails.creditType.split(","));
          let credit_type_name = "";
          for (let i = 0; i < ToGuaranteeMethods.length; i++) {
            if (arr.indexOf(ToGuaranteeMethods[i].value) > -1) {
              credit_type_name += ToGuaranteeMethods[i].description + " ";
            }
          }
          this.FinancialDetails.creditType = credit_type_name;
          // console.log(this.FinancialDetails.creditType, credit_type_name);
          //还款方式
          let TorRepayType = this.repayType;
          let arr2 = Array.from(this.FinancialDetails.repayType.split(","));
          let repay_Type = "";
          for (let i = 0; i < TorRepayType.length; i++) {
            if (arr2.indexOf(TorRepayType[i].value) > -1) {
              repay_Type += TorRepayType[i].description + " ";
            }
          }
          this.FinancialDetails.repayType = repay_Type;
        }, 200);
        if (this.FinancialDetails.minMoney >= 10000) {
          const newMinMoney = this.FinancialDetails.minMoney / 10000 + "万元";
          this.FinancialDetails.ToMinMoney = newMinMoney;
        } else if (this.FinancialDetails.minMoney < 10000) {
          const newMinMoney = this.FinancialDetails.minMoney + "元";
          this.FinancialDetails.ToMinMoney = newMinMoney;
        }

        if (this.FinancialDetails.maxMoney >= 10000) {
          const newMaxMoney = this.FinancialDetails.maxMoney / 10000 + "万元";
          this.FinancialDetails.ToMaxMoney = newMaxMoney;
        } else {
          const newMaxMoney = this.FinancialDetails.maxMoney + "元";
          this.FinancialDetails.ToMaxMoney = newMaxMoney;
        }
      });
    },
    // 下载
    async Download(item) {
      // const url = this.baseUrl + "admin/file/get?ossFilePath=" + item.imgUrl;
      // let dom = document.createElement("a");
      // dom.style.display = "none";
      // dom.href = url;
      // dom.setAttribute("download", item.FilesName);
      // document.body.appendChild(dom);
      // dom.click();
      let itemList = item;
      let res = await download({ ossFilePath: itemList.imgUrl });
      if (res) {
        let blob = res;
        const fileName = itemList.FilesName;
        let downloadElement = document.createElement("a");
        let binaryData = [];
        binaryData.push(blob);
        // "|.doc,.docx,.xls,.xlsx,.pdf,.zip,.7z"
        let href = window.URL.createObjectURL(
          new Blob(binaryData, {
            type: "application/pdf||doc||docx||xls||xlsx||zip||7z",
          })
        ); //创建下载的链接
        downloadElement.href = href;
        downloadElement.download = decodeURIComponent(fileName); //解码
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      }
    },
    // 打包下载
    async Package(financeProductId) {
      let params = {
        financeProductId: financeProductId,
      };
      let res = await packageDownload(params);
      if (res) {
        let blob = res;
        const fileName = this.FinancialDetails.title + "贷款相关材料.zip";
        let downloadElement = document.createElement("a");
        let binaryData = [];
        binaryData.push(blob);
        let href = window.URL.createObjectURL(
          new Blob(binaryData, { type: "application/zip" })
        ); //创建下载的链接
        downloadElement.href = href;
        downloadElement.download = decodeURIComponent(fileName); //解码
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.LoanDetails {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  .headerLogo {
    width: 95%;
    h5 {
      font-size: 20px;
      margin-left: 150px;
    }
  }
  /* 贷款详情 */
  .LoanInformation {
    width: 80%;
    border: solid 1px black;
    margin: 5px 5px;
    .LoanInformation_top {
      width: 70%;
      height: 100px;
      display: flex;
      img {
        margin-top: 5px;
        margin-left: 5px;
        width: 300px;
        height: 100px;
        // box-shadow: 4px 4px 15px #ccc;
      }
      h4 {
        margin-left: 100px;
        line-height: 20px;
        font-weight: bold;
        font-size: 2em;
      }
    }
    .LoanInformation_center {
      width: 100%;
      display: flex;
      justify-content: space-around;
      padding-top: 15px;
      .LoanInformation_bottom_item {
        display: flex;
        flex-direction: column;
        .ColItem {
          color: #f59a23;
          font-size: 2.3em;
          text-align: center;
        }
        .ColItem2 {
          color: #7f7f7f;
          font-size: 1.8em;
          text-align: center;
        }
      }
    }
    .ModeOfRepayment_bottom {
      width: 95%;
      padding: 15px 0px;
      display: flex;
      .repayment {
        width: 200px;
        height: 100%;
        font-weight: bold;
        font-size: 2em;
        margin-left: 40px;
        .Bold {
          width: 140px;
          height: 100%;
        }
      }
      .BoldText {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        font-size: 1.5em;
      }
    }
  }
  /* 产品介绍 */
  .ProductIntroduction {
    width: 80%;
    height: 200px;
    display: flex;
    flex-direction: column;
    border: solid 1px black;
    padding-top: 20px;
    margin: 5px 5px;
    .ProductIntroductionItem {
      width: 95%;
      padding: 15px 0px;
      display: flex;
      .repayment {
        width: 200px;
        height: 100%;
        font-weight: bold;
        font-size: 2em;
        margin-left: 40px;
        .Bold {
          width: 140px;
          height: 100%;
        }
      }
      .BoldText {
        width: 70%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        font-size: 1.5em;
      }
    }
  }
  /*申请条件*/
  .ApplicationConditions {
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    border: solid 1px black;
    padding-top: 20px;
    margin: 5px 5px;
    .ProductIntroductionItem {
      width: 95%;
      padding: 15px 0px;
      display: flex;
      .repayment {
        width: 200px;
        height: 100%;
        font-weight: bold;
        font-size: 2em;
        margin-left: 40px;
        .Bold {
          width: 140px;
          height: 100%;
        }
      }
      .BoldText {
        width: 70%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        font-size: 1.5em;
      }
    }
  }
  /*相关材料*/
  .RelatedMaterials {
    width: 80%;
    height: 200px;
    display: flex;
    flex-direction: column;
    border: solid 1px black;
    padding-top: 20px;
    margin: 5px 5px;
    .ProductIntroductionItem {
      width: 95%;
      padding: 15px 0px;
      display: flex;
      .repayment {
        width: 200px;
        height: 100%;
        font-weight: bold;
        font-size: 2em;
        margin-left: 40px;
        .Bold {
          width: 140px;
          height: 100%;
          .el-button {
            margin-left: 10px;
          }
        }
      }
      .BoldText {
        width: 70%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        font-size: 1.5em;
        ul {
          padding: 0;
          margin: 0;
          float: left;
          list-style: none;
        }
      }
    }
  }
}
</style>
